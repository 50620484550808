import React from "react";
import { RoomTwoTone } from "@material-ui/icons";
import clsx from "clsx";

export const LocationPin = ({ text, adress, ...props }) => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        transform: "translate(-50%, -50%)",
      }}
    >
      <RoomTwoTone fontSize="large" className="text-orange-500" />
      {props.$geoService.transform_.zoom > 14 && (
        <div
          className={clsx(
            "max-h-max max-w-xl bg-orange-200 p-2 text-base rounded"
          )}
        >
          {text}
          {props.$hover && (
            <>
              <br />
              <span
                className={clsx("whitespace-nowrap text-sm text-warmGray-700")}
              >
                {adress}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
