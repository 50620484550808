import React from "react";
import GoogleMapReact from "google-map-react";
import { LocationPin } from "./LocationPin";

const location = {
  adress: "Albrecht Bühring Straße 9",
  name: "Ferienwohnung",
  lat: 50.543562,
  lng: 12.292622,
};

const otherPins = [
  {
    adress: "Heinrich-Heine Straße 3",
    name: "Supermarkt",
    lat: "50.545452",
    lng: "12.299574",
  },
  {
    adress: "Bahnhofstraße 48",
    name: "Bahnhof",
    lat: "50.547956",
    lng: "12.301778",
  },
];

export const Map = ({ zoomLevel = 15, className }) => {
  return (
    <div className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.name}
          adress={location.adress}
        />
        {otherPins.map((location) => (
          <LocationPin
            key={location.name}
            lat={location.lat}
            lng={location.lng}
            text={location.name}
            adress={location.adress}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};
