import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Hero,
  Layout,
  CoverCard,
  DynamicGridGallery,
  ContactForm,
  FlatFeatures,
} from "components/index";
import { SEO, destructureImageData } from "utils/index";
import { Map } from "../components/Map";
import clsx from "clsx";
import { centerInGrid, spanGrid } from "../components/Layout";

const inheritGrid = {
  className: "grid col-span-full",
  style: { grid: "inherit" },
};

const sectionHeadline =
  "text-3xl lg:text-5xl border-b-4 border-orange-200 max-w-max pb-2";
const sectionSubHeadline = "text-lg lg:text-xl mt-12 font-semibold";
const sectionText = "text-base lg:text-xl max-w-4xl";

const IndexPage = () => {
  //Get Data and make necessary transformations to it
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      heroImage: file(relativePath: { regex: "/Göltztalbrücke/" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wohnungen: allWohnungenJson {
        nodes {
          cover {
            alt: name
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          bookable
          title
          slug
        }
      }

      gardenImages: allFile(
        filter: {
          relativePath: { regex: "/images/garden/" }
          extension: { eq: "jpg" }
        }
      ) {
        nodes {
          alt: name
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `);

  const { heroImage, wohnungen, gardenImages } = data;

  return (
    <Layout contentBox={clsx("space-y-20 lg:space-y-48")}>
      <SEO title="Homepage" />

      <Hero
        img={heroImage.childImageSharp.fluid}
        className={clsx("h-vh-9/10", spanGrid)}
      >
        <h2 className="text-3xl md:text-5xl lg:text-7xl text-orange-800">
          Besuchen Sie uns in einer der schönsten Ecken Deutschlands.
        </h2>
      </Hero>

      <section {...inheritGrid}>
        <div className={clsx(centerInGrid)}>
          <h2 className={clsx(sectionHeadline)}>Urlaub bei uns!</h2>
          <p className={clsx("mt-6 mb-10", sectionText)}>
            Als Gast in unserem Haus wohnen Sie im Herzen des Vogtlands und
            können leicht die gesamte Region erkunden. Egal ob Sie Ihren Urlaub
            aktiv oder entspannt gestalten möchten, das Vogtland hat für Sie
            etwas zu bieten. Zusätzlich steht jedem unserer Gäste kostenlos der
            Pool und Garten zur Verfügung.
          </p>
        </div>
        <Map className={clsx("h-vh-4/10", spanGrid)} />
      </section>

      <section {...inheritGrid}>
        <div className={clsx(centerInGrid)}>
          <h2 className={clsx(sectionHeadline)}>Unsere Wohnungen</h2>
          <p className={clsx("mt-4", sectionText)}>
            Unsere Wohnungen sind hell und ruhig gelegen. Beide bieten mit ca.
            50m² auch reichlich Platz für Familien.
          </p>
          <h3 className={sectionSubHeadline}>Grundausstattung</h3>
          <FlatFeatures className="mt-4" />
          <div className="mt-8">
            <h3 className={sectionSubHeadline}>
              Machen Sie sich selbst ein Bild
            </h3>
            <div className="grid md:grid-cols-2 gap-8 mt-4">
              {wohnungen.nodes.map((wohnung) => (
                <CoverCard
                  to={`/${wohnung.slug}`}
                  key={wohnung.title}
                  content={wohnung}
                  className="flex-1 min-w-max"
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section {...inheritGrid}>
        <h2 className={clsx(centerInGrid, sectionHeadline)}>
          Garten und Anlage
        </h2>
        <DynamicGridGallery
          images={destructureImageData(gardenImages)}
          height="80vh"
          className={clsx("mt-8", spanGrid)}
        />
      </section>

      <ContactForm className={clsx("max-w-4xl", centerInGrid)} />
    </Layout>
  );
};

export default IndexPage;
